import Vue from 'vue'
import App from './App.vue'
import Vuesax from 'vuesax'
import 'vuesax/dist/vuesax.css' //Vuesax styles
import VueClipboard from 'vue-clipboard2'
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'

Vue.config.productionTip = false
Vue.use(Vuesax, {
  // options here
})
Vue.use(VueClipboard)

new Vue({
  render: h => h(App),
}).$mount('#app')
