<template>
  <div id="app">
    <vs-row vs-type="flex" vs-justify="center">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="4">
          <h1 style="color: #05606e">Anon Recipient Tool</h1>
        </vs-col>
    </vs-row>
    <br>
    <div style="margin-top: 5%">
      <vs-row vs-type="flex" vs-justify="center">
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="4">
                <vs-input type="email" v-model="sender" :label-placeholder="'From: '+sender_label" icon-pack="fas" icon="fa-paper-plane">
                  <template v-if="sender !== '' && !validEmail(sender)" #message-danger>
                    Email Invalid
                  </template>
                </vs-input>
          </vs-col>
      </vs-row>
    <br>
      <vs-row vs-type="flex" vs-justify="center">
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="4">
                <vs-input type="email" v-model="recipient" :label-placeholder="'To: '+recipient_label" icon-pack="fas" icon="fa-reply">
                  <template v-if="recipient !== '' && !validEmail(recipient)" #message-danger>
                    Email Invalid
                  </template>
                </vs-input>
      </vs-col>
      </vs-row>
    <br>
      <vs-row vs-type="flex" vs-justify="center">
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
              <div class="result" v-if="validEmail(sender) && validEmail(recipient)">
                <b style="color: #05606e; font-size: 17pt; float: left" >{{ dest_addr }}</b>
                <vs-button v-clipboard:copy="dest_addr" color="#05606e" type="flat" icon-pack="fas" icon="fa-copy" style="float: right; margin-left: 15px" size="small"/>
              </div>
        </vs-col>
      </vs-row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {
  },
  data: () => {
    return {
      sender: '',
      recipient: '',
      reg: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
    }
  },
  methods: {
    validEmail(input) {
      return this.reg.test(input)
    }
  },
  computed: {
    dest_addr: function () {
      if(!this.validEmail(this.sender) || !this.validEmail(this.recipient)) {
        return ''
      }

      const senderArray = this.sender.split('@')
      const recipientArray = this.recipient.split('@')

      return senderArray[0]+"+"+recipientArray[0]+"="+recipientArray[1]+"@"+senderArray[1];
    },
    sender_label: function () {
      if(this.sender === '') {
        return 'Fake Sender'
      }

      return this.sender
    },
    recipient_label: function () {
      if(this.recipient === '') {
        return 'Recipient'
      }

      return this.recipient
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.result {
  background-color: rgba(58, 231, 225, 0.3);
  border-radius: 5px;
  padding: 10px;
  margin-top: 50px;
}
</style>
